<template>
  <div>
    <form @submit.prevent="importarRiesgos">
      <div class="columns is-desktop">
        <div class="column is-3">
          <b-field label="Entorno de Referencia">
            <b-select
              placeholder="Entorno"
              icon="server"
              required
              v-model="entornoOrigen"
            >
              <option
                v-for="entorno in entornos"
                :key="entorno.id"
                :value="entorno.id"
              >
                {{ entorno.nombre }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Centro de Referencia">
            <multiselect
              v-model="centroOrigen"
              id="ajax"
              label="nombre"
              track-by="id"
              placeholder="Buscar centro..."
              selectLabel="Pulsa enter para seleccionar"
              open-direction="bottom"
              :preserve-search="true"
              :options="centros"
              :multiple="false"
              :searchable="true"
              :loading="isLoading"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :options-limit="1000"
              :max-height="600"
              :show-no-results="true"
              :hide-selected="false"
              :disabled="importacion.entornoOrigen == 0"
              @search-change="listarCentros"
            >
              <template slot="tag" slot-scope="{ option, remove }">
                <span class="selectTag">
                  <span>{{ option.nombre }}</span>
                  <span class="ml-2" @click="remove(option)">
                    <i class="fas fa-times-circle"></i>
                  </span>
                </span>
              </template>
              <template slot="afterList" v-if="tienePaginaSiguiente">
                <div v-observe-visibility="estaAlFinal" />
                Cargando...
              </template>
              <span slot="noResult">No se ha encontrado ningún centro.</span>
            </multiselect>
          </b-field>
        </div>
      </div>
      <div class="columns is-desktop">
        <div class="column">
          <strong>Seleccione la información a importar</strong>
        </div>
        <div class="column">
          <strong>Acción al importar</strong>
        </div>
      </div>

      <div v-if="listadoModelosGestion.length > 0" class="block ">
        <div class="columns is-desktop mt-2">
          <div class="column">
            <b-field class="mt-2">
              <b-checkbox v-model="importacion.permisos.importar">
                <strong>Permisos</strong>
              </b-checkbox>
            </b-field>
          </div>
          <div class="column">
            <b-field>
              <b-select
                placeholder="Elija acción..."
                icon="file-replace"
                v-model="importacion.permisos.accion"
                :disabled="!importacion.permisos.importar"
              >
                <option value="sustituir">Sustituir</option>
                <option value="anadir">Añadir</option>
              </b-select>
            </b-field>
          </div>
        </div>
        <div v-if="listadoModelosGestion.length > 0" class="columns is-desktop mt-2 mb-0">
          <div class="column">
            <b-field>
              <b-checkbox v-model="checkModelosGestionCompleto" @input="checkTodosModelosGestion">
                <strong>Modelos de Gestión</strong>
              </b-checkbox>
            </b-field>
          </div>
          <div class="column">
            <b-field>
              <b-select
                placeholder="Elija acción..."
                icon="file-replace"
                v-model="importacion.modelosGestion[0].accion"
                :disabled="true"
                :readonly="true"
              >
                <option selected value="anadir">Añadir</option>
                <option value="sustituir">Sustituir</option>
              </b-select>
            </b-field>
          </div>
        </div>

        <div v-for="(modelo, idx) in listadoModelosGestion" :key="idx" class="columns is-desktop mt-0 mb-0" >
          <div class="column">
            <b-checkbox v-model="importacion.modelosGestion[idx].importar" @input="checkModelo" class="pl-4">
                {{modelo.nombre}}
            </b-checkbox>
          </div>
          <div class="column">&nbsp;</div>
        </div>
      </div>

      <div class="level-right mr-2">
        <b-button
          type="is-danger"
          outlined
          @click="cancelar"
          class="mt-1 is-right mr-2"
        >
          Cancelar
          <span class="icon ml-2">
            <i class="fas fa-save"></i>
          </span>
        </b-button>
        <b-button
          type="is-primary"
          native-type="submit"
          class="mt-1 is-right"
          :disabled="disableImportar"
        >
          Aceptar
          <span class="icon ml-2 alinearBot">
            <b-icon icon="cog"></b-icon>
          </span>
        </b-button>
      </div>
    </form>
    <modal-progreso
      :faseProceso="faseProceso"
      :textoProgreso="textoProgreso"
      :valorProgreso="valorProgreso"
      :mostrarBarraProgreso="mostrarBarraProgreso"
    ></modal-progreso>
  </div>
</template>
<script>
import apiCentros from "@/services/centros.js";
import apiEntornos from "@/services/entornos.js";
import apiRiesgos from "@/services/riesgos.js";
import apiHistoricoImportaciones from "@/services/historicoImportaciones.js";
import apiEstatusImportaciones from "@/services/estatusImportaciones.js";
import Multiselect from "vue-multiselect";
import ModalProgreso from "@/components/ModalProgreso.vue";
import { uuid } from "vue-uuid";

export default {
  name: "FormularioRiesgos",
  components: { Multiselect, ModalProgreso },
  props: {
    id: Number,
    entornoId: Number,
    centroDestino: String,
    entornoDestino: String,
  },
  computed: {
    hayCentros() {
      var centros = Boolean(this.centros.length);
      return !centros;
    },
  },
  data() {
    return {
      centros: [],
      mensajeError: "",
      tipoMensaje: "",
      entorno: apiEntornos.crear(),
      entornos: [],
      centroOrigenSeleccionado: "",
      isLoading: false,
      cargadoCentro: false,
      pagina: 0,
      tienePaginaSiguiente: true,
      checkModelosGestionCompleto: false,
      importacion: {
        modelosGestion: [],
        permisos: {
          importar: false,
          accion: "anadir",
        },
      },
      listadoModelosGestion: [],
      entornoOrigen: 0,
      centroOrigen: apiCentros.crear(),
      entornoDestinoId: this.entornoId,
      centroDestinoId: this.id,
      disableImportar: true,
      mostrarBarraProgreso: false,
      textoProgreso: "",
      valorProgreso: 0,
      faseProceso: "",
    };
  },
  methods: {
    mostrarMensaje(msg, tipo, resumen) {
      if (tipo == "is-danger") {
        this.$buefy.toast.open({
          message: msg,
          type: tipo,
        });
        return;
      }
      this.$buefy.dialog.alert({
        title: msg,
        message: resumen,
        type: tipo,
        hasIcon: false,
        icon: "info-circle",
        iconPack: "fa",
        ariaRole: "alertdialog",
        ariaModal: true,
      });
    },
    cancelar() {
      this.$router.push({ path: "/centros" });
    },
    async listarCentros(term) {
      if (term != undefined && term == "") {
        this.centros = [];
      } else if (term == undefined) {
        term = "";
      }
      var params = {
        entornoId: this.entornoOrigen,
        path: "centros/listar",
        limite: 50,
        pagina: this.pagina,
        term: term,
      };
      if (term != "") {
        params = {
          entornoId: this.entornoOrigen,
          path: "centros/listar",
          limite: 50,
          pagina: 0,
          term: term,
        };
      }

      return apiCentros.listar(params).then((respuesta) => {
        if (!respuesta.centros.length) {
          this.tienePaginaSiguiente = false;
        }
        if (term != "") {
          this.centros = respuesta.centros;
          this.tienePaginaSiguiente = false;
          return;
        }
        this.tienePaginaSiguiente = true;
        this.centros = this.centros.concat(respuesta.centros);
      });
    },
    estaAlFinal(alfinal) {
      if (alfinal) {
        this.pagina++;
        this.listarCentros();
      }
    },
    actualizarEntorno() {
      this.pagina = 0;
      this.centros = [];
    },
    checkModelo() {
      this.checkModelosGestionCompleto = this.importacion.modelosGestion.every(
        (modelo) => modelo.importar
      );
    },
    checkTodosModelosGestion() {
      if (this.checkModelosGestionCompleto) {
        this.importacion.modelosGestion.forEach((modelo) => {
          modelo.importar = true;
        });
      } else {
        this.importacion.modelosGestion.forEach((modelo) => {
          modelo.importar = false;
        });
      }
    },
    importarRiesgos() {
      this.$buefy.dialog.confirm({
        title: "Atención",
        message:
          "Va a importar la información en el entorno <b>" +
          this.entornoDestino +
          "</b> y el centro <b>" +
          this.centroDestino +
          "</b>",
        confirmText: "Importar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.procesarImportarRiesgos(),
      });
    },
    async procesarImportarRiesgos() {
      var msg = "Importación realizada.";
      var tipo = "is-success";
      var resumen = "Se han importado: ";
      this.uuid = uuid.v1();
      this.faseProceso = "Exportación";
      this.valorProgreso = 100 / Object.keys(this.importacion).length;
      this.textoProgreso = Object.keys(this.importacion)[0];

      this.mostrarProgreso();

      var resultadoExportacion = await apiRiesgos.exportar(
        this.entornoOrigen,
        this.centroOrigen.id,
        this.importacion,
        this.uuid
      );
      if (resultadoExportacion.error !== undefined) {
        msg = "Se ha producido un error: <br>" + resultadoExportacion.error;
        tipo = "is-danger";
        this.mostrarMensaje(msg, tipo);
        return;
      }

      this.uuid = uuid.v1();
      this.faseProceso = "Importación";
      this.valorProgreso = 100 / Object.keys(this.importacion).length;
      this.textoProgreso = Object.keys(this.importacion)[0];
      this.mostrarProgreso(false);

      var resultadoImportacion = await apiRiesgos.importar(
        this.entornoDestinoId,
        this.centroDestinoId,
        resultadoExportacion,
        this.uuid
      );
      if (resultadoImportacion.error !== undefined) {
        msg = "Se ha producido un error: <br>" + resultadoImportacion.error;
        tipo = "is-danger";
        this.mostrarMensaje(msg, tipo);
        return;
      }
      this.guardarHistorico(this.importacion, resultadoImportacion);
      resumen = this.maquetarResumen(resultadoImportacion);
      this.mostrarBarraProgreso = false;
      this.mostrarMensaje(msg, tipo, resumen);
      // this.$router.push({ path: "/centros" });
    },

    guardarHistorico(importacion, resultado) {
      var historicoImportaciones = apiHistoricoImportaciones.crear();
      var res = JSON.stringify(resultado);
      historicoImportaciones.aplicacion = "riesgos";
      historicoImportaciones.entornoDestinoId = this.entornoDestinoId;
      historicoImportaciones.centroDestinoId = this.centroDestinoId;
      historicoImportaciones.centroDestino = this.centroDestino;
      historicoImportaciones.entornoOrigenId = this.entornoOrigen;
      historicoImportaciones.centroOrigenId = this.centroOrigen.id;
      historicoImportaciones.centroOrigen = this.centroOrigenSeleccionado;
      historicoImportaciones.importacion = this.limpiarImportacion(
        importacion,
        resultado
      );
      historicoImportaciones.resultado = res;

      apiHistoricoImportaciones.insertar(historicoImportaciones);
    },
    cambiarCentroOrigen() {
      this.centroOrigenSeleccionado = this.centroOrigen.nombre;
      if(this.centroOrigenSeleccionado != ""){
        this.cargadoCentro = true;
        this.cargarModelosGestion();
      }
    },
    async cargarModelosGestion() {
       const params = {
        entornoOrigen: this.entornoOrigen,
        centroOrigen: this.centroOrigen,
      };
      this.listadoModelosGestion = await apiRiesgos.listarModelosGestion(params);
      if(this.listadoModelosGestion.length > 0){
          this.importacion.modelosGestion = this.listadoModelosGestion.map(modelo => ({
          id: modelo.id,
          nombre: modelo.nombre,
          accion: "anadir",
          importar: false,
        }));
      }
    },
    limpiarImportacion(importacion, res) {
      var resultado = {};
      for (var prop in importacion) {
        if (
          !!Object.getOwnPropertyDescriptor(importacion[prop], "importar") &&
          importacion[prop].importar
        ) {
          const result = prop.replace(/([A-Z])/g, " $1");
          const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
          resultado[prop] = {
            accion: importacion[prop].accion,
            resultado: res[prop].resultado,
            nombre: finalResult,
          };
        }
      }
      return JSON.stringify(resultado);
    },
    puedeImportar() {
      var res =
        Boolean(
          this.entornoOrigen &&
            this.centroOrigen.id != undefined &&
            this.centroOrigen.id
        ) && this.hayRiesgosSeleccionadas();
      this.disableImportar = !res;
    },
    hayRiesgosSeleccionadas() {
      for (let elemento in this.importacion) {
        if (
          !!Object.getOwnPropertyDescriptor(
            this.importacion[elemento],
            "importar"
          ) &&
          this.importacion[elemento].importar
        ) {
          return true;
        }
        if (elemento == "modelosGestion") {
          for (let modelo in this.importacion[elemento]) {
            if (this.importacion[elemento][modelo].importar) {
              return true;
            }
          }
        }
      }
      return false;
    },
    maquetarResumen(resumen) {
      var res = "";
      var fila = "";
      var icono = "";
      for (var aplicacion in resumen) {
        if (aplicacion === "modelosGestion") {
          const modelosArray = Object.values(resumen[aplicacion]);
          modelosArray.forEach((modelo) => {
            if (modelo.resultado != "ok") {
              icono = '<i class="fas fa-exclamation-triangle has-text-danger is-size-3"></i>';
            } else {
              icono = '<i class="fas fa-check-circle has-text-success is-size-3"></i>';
            }
            fila = '<div class="columns"><div class="column is-1">' + icono + '</div><div class="column is-11">' + modelo.mensaje + "</div></div>";
            res += fila;
          });

        } else{
          if (resumen[aplicacion].resultado != "ok") {
            icono = '<i class="fas fa-exclamation-triangle has-text-danger is-size-3"></i>';
          } else{
            icono = '<i class="fas fa-check-circle has-text-success is-size-3"></i>';
          }
          fila = '<div class="columns"><div class="column is-1">' + icono + '</div><div class="column is-11">' +  resumen[aplicacion].mensaje + "</div></div>";
          res += fila;
        }
      }
      return res;
    },
    async mostrarProgreso(importacion = true, that = this) {
      apiEstatusImportaciones.cargarProgreso(this.uuid).then((respuesta) => {
        if (
          (importacion &&
            (respuesta.total == 0 || respuesta.progreso < respuesta.total)) ||
          (!importacion && that.mostrarBarraProgreso)
        ) {
          that.mostrarBarraProgreso = true;
          if (respuesta.mensaje != "") that.textoProgreso = respuesta.mensaje;
          if (respuesta.valorProgreso != 0)
            that.valorProgreso = (100 * respuesta.progreso) / respuesta.total;
          setTimeout(function () {
            that.mostrarProgreso(importacion);
          }, 1000);
        }
      });
    },
  },
  async mounted() {
    this.entornos = await apiEntornos.listar();
  },
  watch: {
    centroOrigen: function () {
      this.puedeImportar();
      this.cambiarCentroOrigen();
    },
    entornoOrigen: function () {
      this.puedeImportar();
      this.actualizarEntorno();
    },
    importacion: {
      handler: function () {
        this.puedeImportar();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style>
.dialog.modal .modal-card {
  width: 50vw;
  max-width: 50vw;
}
</style>
