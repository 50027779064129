import { backend } from "./backend";

function listarTipos(params) {
  return backend.get("clienteEntorno&entornoId=" + params.entornoOrigen + "&centroId=" + params.centroOrigen.id + "&path=riesgos/listarTipos").then((response) => {
    for (let i = 0; i < response.tiposRiesgo.length; i++) {
      response.tiposRiesgo[i].accion = "anadir";
    }
    return response.tiposRiesgo;
  });
}

function listarModelosGestion(params) {
  return backend.get(
      "clienteEntorno&entornoId=" +
          params.entornoOrigen +
          "&centroId=" +
          params.centroOrigen.id +
          "&path=riesgos/listarModelosGestion"
  )
  .then((response) => {
    return response.modelosGestion;
  });
}
function exportar(entornoOrigen, centroOrigen, importacion, uuid) {
  return backend.post("riesgos/exportar", {
    entornoOrigen: entornoOrigen,
    centroOrigen: centroOrigen,
    importacion: importacion,
    uuid: uuid,
  });
}

function importar(entornoDestino, centroDestino, exportacion, uuid) {
  return backend.post("riesgos/importar", {
    entornoDestino: entornoDestino,
    centroDestino: centroDestino,
    exportacion: exportacion,
    uuid: uuid,
  });
}

export default {
  listarTipos,
  listarModelosGestion,
  exportar,
  importar,
};
